import React from 'react';
import './App.css';

function App() {
function mico(){
  document.title = "Okay"
}
  return (
    <h1 onClick={mico()}>Ok</h1>
  );
}

export default App;
